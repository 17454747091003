import styled, {css, device } from "@styled";

export const LanguageFactsWrapper = styled.section`
  padding:60px 0 40px 0;

  ${device.medium} {
    padding:80px 0 50px 0;
  }

  ${device.large} {
    padding: 70px 0;
  }
  .container{
      position: relative;
      ${device.xxlarge} {
        max-width:1570px;
      }
  }
`
export const LanguageFactsInner = styled.div`
  .introText p {
    font-size: 17px;
  }
`
export const StyledTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2; 
  margin:0;
  ${device.medium} {
    font-size: 28px;
  }
  ${device.xlarge} {
    font-size: 32px;
  }
  ${device.xxlarge} {
    font-size: 36px;
  }
`
export const ClientFactHeadingWrap = styled.div`
  margin-bottom: 15px;
  ${device.small} {
    margin-bottom: 20px;
  }
  ${device.large} {
    margin-bottom: 25px;
  }
  ${device.xxlarge} {
    margin-bottom: 30px;
  }
`
export const ClientFactTitleWrap = styled.div`
 display: flex;
 align-items:center;
 grid-gap:8px;
 ${device.large} {
   grid-gap:15px;
  }
 img{
  width:50px;
  ${device.large} {
    width:auto;
  }
 }
`
export const ClientFactText = styled.p`
  font-size: 16px;
  color: #050017;
  line-height: 1.3;
  margin-top:20px;
  ${device.small} {
    font-size: 18px;
  }
  ${device.xlarge} {
    font-size: 20px;
    margin-top:30px;
  }
`
export const LanguageTranslationItem = styled.div`
  color: #fff;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
export const LanguageFactsList = styled.ul`
  list-style: disc;
  padding-left: 25px;
`
export const LanguageFactsItem = styled.li`
  font-size: 16px;
  color: #050017;
  line-height: 1.3;
  ${device.small} {
    font-size: 18px;
  }
  ${device.xlarge} {
    font-size: 20px;
  }
  &::marker{
    color:#f75019;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
    &::marker{
    color:#80cc28; 
  }
  `}
  ${(props) =>
    props.layout === 2 &&
    css`
    &::marker{
    color:#273b83; 
  }
  `}
  ${(props) =>
    props.layout === 3 &&
    css`
    &::marker{
    color:#020099; 
  }
  `}  
  &:not(:last-child) {
    margin-bottom: 10px;
    ${device.large} {
      margin-bottom: 15px;
    }
  }
`
